/* Header.css */
.header {
  background: #f0ece2;
  color: #333;
  padding: 1rem 0;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.header .logo {
  max-height: 120px;
  width: auto;
}

.nav-links, .social-icons {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links {
  flex: 1;
  justify-content: flex-start;
}

.nav-links li {
  margin-left: 2.5rem;
}

.nav-links a {
  color: #333;
  text-decoration: none;
  font-size: 1.1rem;
}

.social-icons {
  justify-content: center;
  flex: 1;
}

.social-icons li {
  margin: 0 0.5rem;
}

.social-icons a {
  color: #333;
  font-size: 2.5rem;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #1da1f2; /* Example color for hover effect */
}

.menu-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }
  .header .logo {
    align-self: flex-start;
  }

  .nav-links, .social-icons {
    flex-direction: column;
    display: none;
    width: 100%;
    text-align: center;
  }

  .nav-links.open, .social-icons.open {
    display: flex;
  }

  .nav-links li, .social-icons li {
    margin: 0.5rem 0;
  }

  .menu-icon {
    display: block;
    align-self: flex-end;
  }
}
