/* Portfolio.css */
#portfolio {
    padding: 50px 0;
    text-align: center;
}
#portfolio h2 {
    font-size: 36px; 
    margin-bottom: 20px;
}
.portfolio-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.portfolio-item {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}
.portfolio-item:hover {
    transform: scale(1.1);
}

.portfolio-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}
.portfolio-item:hover img {
    transform: scale(1.1); 
}