.hero {
  position: relative;
  background: url('/public/hero-background.jpg') no-repeat center center/cover;
  background-size: cover;
  color: #fff; /* Change to white for better contrast */
  padding: 8rem 0;
  height: 100vh;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 1; /* Ensure it sits above the background image but below the text */
}

.container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 2; /* Ensure the text is above the overlay */
}

.hero-title {
  font-size: 5rem;
  color: #fff; /* Change to white for better contrast */
  margin-bottom: 20px;
  font-weight: 700;
  z-index: 2; /* Ensure the text is above the overlay */
  position: relative;
}

.hero-subtitle {
  font-size: 3.0rem;
  color: #fff; /* Change to white for better contrast */
  margin-bottom: 30px;
  z-index: 2; /* Ensure the text is above the overlay */
  position: relative;
}

.hero-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
  z-index: 2; /* Ensure the button is above the overlay */
  position: relative;
}

.hero-button:hover {
  background-color: #004d40;
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .hero-button {
    padding: 12px 25px;
    font-size: 1rem;
  }
}
