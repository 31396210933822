.services {
  padding: 20px;
  background-color: #f9f9f9;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.services-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.services-item {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

.services-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: #eee;
  padding: 10px;
  border-radius: 5px;
}

.services-item-header h3 {
  margin: 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

li {
  padding: 5px 0;
}

.collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.collapsible-content.expanded {
  max-height: 1000px; /* Adjust as needed */
}
