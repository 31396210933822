.footer {
  background: #333;
  color: white;
  padding: 1rem 0;
}

.footer .container {
  max-width: 800px;
  margin: 0 auto;
}

.footer p {
  margin: 0;
}
