.about {
  padding: 3rem 0;
  background: #f5f5f5;
  text-align: center;
}

.about .container {
  max-width: 800px;
  margin: 0 auto;
}

.about h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.about p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: #555;
}

@media (max-width: 768px) {
  .about h2 {
    font-size: 2rem;
  }

  .about p {
    font-size: 1rem;
  }
}
