.contact {
  padding: 2rem 0;
  background: #f9f9f9;
  text-align: center;
}

.contact .container {
  max-width: 800px;
  margin: 0 auto;
}

.contact h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.contact form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radio-buttons {
  margin-bottom: 1rem;
}

.radio-buttons label {
  margin-right: 1rem;
  font-size: 1.2rem;
}

.contact-input {
  display: flex;
  flex-direction: column; /* Ensure elements are stacked vertically */
  width: 100%;
  max-width: 500px; /* Adjust width if needed */
  margin-bottom: 1rem;
}

.contact-input input,
.contact-input textarea {
  padding: 0.75rem; /* Increased padding for better touch targets */
  font-size: 1rem;
  width: 100%; /* Ensure full width */
  margin-bottom: 1rem;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border: 1px solid #ccc; /* Add a light border */
  border-radius: 5px; /* Rounded corners */
}

.contact-input textarea {
  height: 150px; /* Increase height for better usability */
  resize: vertical; /* Allow vertical resizing */
}

button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: white;
  background: #333;
  border: none;
  cursor: pointer;
}

button:hover {
  background: #555;
}

.message-suggestions {
  margin-top: 1rem;
}

.message-suggestions p {
  margin: 0 0 0.5rem;
  font-weight: bold;
}

.message-suggestions button {
  background: #eee;
  color: #333;
  padding: 0.5rem;
  margin: 0.25rem;
  border: none;
  cursor: pointer;
}

.message-suggestions button:hover {
  background: #ddd;
}
